import React, { useState } from "react";
import "../../../App.css";

//MUI ELEMENTS
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//ICONS
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
dayjs.extend(dayjsPluginUTC);

const PurchasedForm = ({
  updateDates,
  checkedDatesFlag,
  setChangePageFlag,
}) => {
  const [firstDate, setFirstDate] = useState(
    dayjs.utc("2024-01-01").hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [lastDate, setLastDate] = useState(
    dayjs.utc("2024-12-31").hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );

  const [errors, setErrors] = useState({});
  const [flagWrongPeriod, setFlagWrongPeriod] = useState(false);

  const ValidateForm = () => {
    const newErrors = {};

    // Convert the formatted strings back to dayjs objects
    const firstDateObj = dayjs(firstDate);
    const lastDateObj = dayjs(lastDate);

    // Check if the parsed dates are valid
    if (!firstDate || !firstDateObj.isValid()) {
      newErrors.firstDate = "Inserire la data di inizio validità";
    }

    if (!lastDate || !lastDateObj.isValid()) {
      newErrors.lastDate = "Inserire la data di fine validità";
    }

    // Validate date period (firstDate should be before lastDate)
    if (
      firstDateObj.isSame(lastDateObj, "day") ||
      firstDateObj.isAfter(lastDateObj)
    ) {
      newErrors.firstDate = "Il periodo non è valido";
      newErrors.lastDate = "Il periodo non è valido";
      setFlagWrongPeriod(true);
    } else {
      setFlagWrongPeriod(false);
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formErrors = ValidateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      checkedDatesFlag(false);
    } else {
      const offerData = [];

      setErrors({});
      setFlagWrongPeriod(false);

      offerData.push(firstDate, lastDate);

      updateDates(offerData);
      checkedDatesFlag(true);
      setChangePageFlag(false);
    }
  };

  return (
    <>
      <h1
        style={{
          fontFamily: "Calibri",
          color: "#1976d2",
        }}
      >
        INSERIMENTO NUOVO ACQUISTATO
      </h1>
      <h3
        style={{
          fontFamily: "Calibri",
          paddingTop: "2%",
          color: "#1976d2",
        }}
      >
        INSERISCI IL PERIODO:
      </h3>
      <div
        style={{
          paddingTop: "2%",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            style={{
              display: "inline-block",
              width: "48%",
              textAlign: "right",
            }}
          >
            <DesktopDatePicker
              label=""
              inputFormat="DD/MM/YYYY"
              value={firstDate}
              onChange={(newValue) => {
                setFirstDate(
                  newValue.hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.firstDate ? true : false}
                  helperText={errors.firstDate}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "2%",
              textAlign: "left",
            }}
          ></div>
          <div
            style={{
              display: "inline-block",
              width: "48%",
              textAlign: "left",
            }}
          >
            <DesktopDatePicker
              label=""
              inputFormat="DD/MM/YYYY"
              value={lastDate}
              onChange={(newValue) => {
                setLastDate(
                  newValue.hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.lastDate ? true : false}
                  helperText={errors.lastDate}
                />
              )}
            />
          </div>
        </LocalizationProvider>
      </div>
      {flagWrongPeriod && (
        <div
          style={{
            paddingTop: "1%",
            color: "#ff0000",
            fontFamily: "Calibri",
            fontSize: "20px",
          }}
        >
          HAI SCELTO UN INTERVALLO DI DATE NON CORRETTO
        </div>
      )}

      <div style={{ paddingTop: "2.5%" }}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          startIcon={<CheckOutlinedIcon />}
          onClick={handleFormSubmit}
        >
          CONFERMA
        </Button>
      </div>
    </>
  );
};

export default PurchasedForm;
