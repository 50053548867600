import React, { useState, useEffect } from "react";
import "../../App.css";

import OffersListView from "./OffersListView";
import OffersListInsert from "./OffersListInsert";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const OffersListMain = ({ user }) => {
  const [insertPageFlag, setInsertPageFlag] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <div className="acqPage">
        <div
          style={{
            textAlign: "right",
            marginBottom: "1%",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="bluetest"
            startIcon={!insertPageFlag ? <AddCircleIcon /> : <ListAltIcon />}
            onClick={() => setInsertPageFlag(!insertPageFlag)}
          >
            {!insertPageFlag ? "INSERISCI NUOVO LISTINO" : "VISUALIZZA LISTINI"}
          </Button>
        </div>
        <div>
          {!insertPageFlag ? (
            <OffersListView user={user} />
          ) : (
            <OffersListInsert user={user} />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default OffersListMain;
