import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import { LicenseInfo } from "@mui/x-license-pro";
import MenuBar from "./mainComponents/MenuBar";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

LicenseInfo.setLicenseKey(
  "1fe66add28b001d43d99ce22ab8f2d05Tz03Njg2NyxFPTE3MjkyNDY1NTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <MenuBar />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
