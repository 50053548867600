export const menuItems_OBJ = [
  {
    title: "ACQUISTATO",
    url: "/acquistato",
    submenu: [
      {
        title: "GESTIONE ACQUISTATI",
        url: "acquistato",
      },
      {
        title: "CALCOLO FABBISOGNO",
        url: "acquistato-fabbisogno",
      },
      {
        title: "BENCHMARKING",
        url: "acquistato-benchmarking",
      },
      {
        title: "FILE F",
        url: "acquistato-file-f",
      },
    ],
  },
  {
    title: "OFFERTE",
    url: "/offerta-inserimento-nuovo",
    submenu: [
      {
        title: "INSERISCI OFFERTA",
        url: "offerta-inserimento-nuovo",
      },
      {
        title: "LISTINO OFFERTE",
        url: "offerte-visualizza-nuovo",
      },
      {
        title: "CONTORLLO OFFERTE",
        url: "offerte-controllo-nuovo",
      },
    ],
  },
  {
    title: "ABBINAMENTO",
    url: "/abbinamento-main",
    submenu: [
      {
        title: "ABBINAMENTO",
        url: "abbinamento-main",
      },
      {
        title: "ENRICO",
        url: "enrico",
      },
      {
        title: "FILE F",
        url: "filef",
      },
    ],
  },
  // {
  //   title: "SCADENZE BREVETTUALI",
  //   url: "/lista-scadenze-brevettuali",
  //   submenu: [
  //     {
  //       title: "LISTA SCADENZE",
  //       url: "lista-scadenze-brevettuali",
  //     },
  //     {
  //       title: "CALCOLO IMPATTO SCADENZE",
  //       url: "impatto-scadenze-brevettuali",
  //     },
  //   ],
  // },
  // {
  //   title: "GARE PUB",
  //   url: "/gare-pubbliche",
  //   submenu: [
  //     {
  //       title: "INSERIMENTO GARE PUBBLICHE",
  //       url: "gare-pubbliche",
  //     },
  //   ],
  // },
];
