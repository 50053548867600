import React, { useState } from "react";
import "../../../App.css";

//COMPONENTS
import PurchasedInsert from "./PurchasedInsert";
import PurchasedView from "./PurchasedView";

//MUI ELEMENTS
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const PurchasedMain = ({ user }) => {
  const [insertPageFlag, setInsertPageFlag] = useState(false);
  const [changePageFlag, setChangePageFlag] = useState(true);

  return (
    <ThemeProvider theme={theme}>
      <div className="acqPage">
        {changePageFlag && (
          <div
            style={{
              textAlign: "right",
              marginBottom: "1%",
            }}
          >
            <Button
              variant="contained"
              component="label"
              color="bluetest"
              startIcon={!insertPageFlag ? <AddCircleIcon /> : <ListAltIcon />}
              onClick={() => setInsertPageFlag(!insertPageFlag)}
            >
              {!insertPageFlag
                ? "INSERISCI NUOVO ACQUISTATO"
                : "VISUALIZZA ACQUISTATI"}
            </Button>
          </div>
        )}

        <div>
          {!insertPageFlag ? (
            <PurchasedView user={user} setChangePageFlag={setChangePageFlag} />
          ) : (
            <PurchasedInsert
              user={user}
              setChangePageFlag={setChangePageFlag}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default PurchasedMain;
