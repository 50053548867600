import React, { useState, useEffect } from "react";

import Navbar from "./Navbar";
import User from "./User";

import { Link } from "react-router-dom";

const HeaderNEW = (props) => {
  const { user, handleLogout } = props;

  const [userLog, setUserLog] = useState({});

  const handleChangeDates = () => {
    let newUser = {
      title: user.name.toUpperCase(),
      submenu: [
        {
          title: "INFO",
        },
      ],
    };
    setUserLog(newUser);
  };

  useEffect(() => {
    handleChangeDates();
  }, []);

  return (
    <header>
      <div className="nav-area">
        <Link to="/" className="logo">
          HOME
        </Link>
        <Navbar />
        <User
          items={userLog}
          key={0}
          depthLevel={0}
          user={user}
          handleLogout={handleLogout}
        />
      </div>
    </header>
  );
};

export default HeaderNEW;
