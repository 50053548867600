import React, { useEffect, useState, useRef } from "react";
import "../../../App.css";

import * as XLSX from "xlsx";

import filef_years from "../../../utils/filef_years.json";

import equivalenzeService from "../../../services/equivalenze";
import filefService from "../../../services/filef";

import BeatLoader from "react-spinners/BeatLoader";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Slide from "@mui/material/Slide";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import EXP_FILEF_EMPTY from "../../../exports/EMPTY_FILES/EXP_FILEF_EMPTY";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PurchasedFileF = (props) => {
  const { user } = props;

  const fileRef = useRef();
  const acceptableFileName = ["xlsx", "xls"];

  const [confirmedFile, setConfirmedFile] = useState(false);

  const [notMatch, setNotMatch] = useState([]);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const [fileValutation, setFileValutation] = useState([]);

  const [yearSel, setYearSel] = useState(null);

  const [instructionOpen, setInstructionOpen] = useState(false);
  const [savePopUpOpen, setSavePopUpOpen] = useState(false);

  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [loadingFinalSave, setLoadingFinalSave] = useState(false);

  useEffect(() => {}, []);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const handleInstructionOpen = () => {
    setInstructionOpen(true);
  };

  const handleInstructionClose = () => {
    setInstructionOpen(false);
  };

  const handleSavePopUpOpen = () => {
    setSavePopUpOpen(true);
  };

  const handleSavePopUpClose = () => {
    setSavePopUpOpen(false);
    setLoadingFinalSave(false);
  };

  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  const handleFileUploaded = (e) => {
    setLoadingFileUpload(true);
    handleUploadDataControl(e);
  };

  const handleUploadDataControl = async (fileInserted) => {
    let AICList = [];
    let foundedAIC = [];
    let matchFarmadati = [];
    let noMatch = [];

    fileInserted.map((Selected) => {
      if (Selected.AIC) {
        let tempAIC = Selected.AIC.toString();

        tempAIC = tempAIC.trim();

        Selected.AIC = tempAIC;
      }

      if (Selected.AIC.toString().length < 9) {
        let tempAIC = Selected.AIC.toString();

        for (let i = Selected.AIC.toString().length; i < 9; i++) {
          tempAIC = "0" + tempAIC;
        }
        Selected.AIC = tempAIC;
      }

      AICList.push(Selected.AIC);
      return fileInserted;
    });

    equivalenzeService.getAIC(AICList).then((res) => {
      foundedAIC = res;

      let matchList = [];

      fileInserted.map((Selected) => {
        let tempFound = [];
        foundedAIC.map((element) => {
          if (element.data.length > 0 && element.data[0].aic === Selected.AIC) {
            tempFound = element.data[0];
          } else {
          }
          return foundedAIC;
        });

        let matchListTemp = {
          inserted_excel: Selected,
          found_farmadati: tempFound,
        };

        matchList.push(matchListTemp);
        return fileInserted;
      });

      let id_filef_temp = 0;

      matchList.map((Selected) => {
        let temp = [];
        if (
          Selected.found_farmadati.length === 0 ||
          Selected.inserted_excel.AIC === undefined
        ) {
          temp = {
            id_filef_temp: id_filef_temp,
            aic: Selected.inserted_excel.AIC.toString(),
            nome_commerciale_file: Selected.inserted_excel.NOME_COMMERCIALE,
            nome_commerciale: "NO MATCH",
            atc: "NO MATCH",
            atc_complementare: "NO MATCH",
            codice_gruppo_ospedaliero: "NO MATCH",
            descrizione_gruppo_ospedaliero: "NO MATCH",
            descrizione_atc: "NO MATCH",
            principio_attivo: "NO MATCH",
            forma_farmaceutica: "NO MATCH",
            dosaggio: "NO MATCH",
            fascia: "NO MATCH",
            iva: "NO MATCH",
          };
        } else {
          temp = {
            id_filef_temp: id_filef_temp,
            aic: Selected.inserted_excel.AIC.toString(),
            nome_commerciale_file: Selected.inserted_excel.NOME_COMMERCIALE,
            nome_commerciale: Selected.found_farmadati.descrizione_aic,
            atc: Selected.found_farmadati.atc,
            atc_complementare: Selected.found_farmadati.atc_complementare,
            codice_gruppo_ospedaliero:
              Selected.found_farmadati.codice_gruppo_ospedaliero,
            descrizione_gruppo_ospedaliero:
              Selected.found_farmadati.descrizione_gruppo_ospedaliero,
            descrizione_atc: Selected.found_farmadati.descrizione_atc,
            principio_attivo: Selected.found_farmadati.principio_attivo,
            forma_farmaceutica: Selected.found_farmadati.forma_farmaceutica,
            dosaggio: Selected.found_farmadati.dosaggio,
            fascia: Selected.found_farmadati.fascia,
            iva: Selected.found_farmadati.iva,
          };
        }
        matchFarmadati.push(temp);
        id_filef_temp = id_filef_temp + 1;
        return matchList;
      });

      matchFarmadati.map((Selected) => {
        if (Selected.nome_commerciale === "NO MATCH") {
          noMatch.push(Selected);
        }

        return matchFarmadati;
      });

      setFileValutation(matchFarmadati);
      setNotMatch(noMatch);
      setConfirmedFile(true);
      setLoadingFileUpload(false);
    });
  };

  const readDataFromFile = (data) => {
    const wb = XLSX.read(data);

    var mySheetData = {};
    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    mySheetData = jsonData;

    return mySheetData;
  };

  const handleFile = async (e) => {
    const myfile = e.target.files[0];
    if (!myfile) return;

    if (!checkFileName(myfile.name)) {
      alert("Invalid file type!");
      return;
    }

    const data = await myfile.arrayBuffer();
    const mySheetData = readDataFromFile(data);

    handleFileUploaded(mySheetData);
  };

  const handleConfirmSave = async () => {
    setLoadingFinalSave(true);

    const FILE_VAL_TEMP = [...fileValutation];

    let noMatchRemoved = FILE_VAL_TEMP.filter(
      (SEL) => SEL.nome_commerciale !== "NO MATCH"
    );

    let finalToSave = {
      anno: yearSel.nome,
      obj_file: noMatchRemoved,
    };

    // console.log("finalToSave", finalToSave);

    filefService.setToken(user.token);

    await filefService.salvaFileF(finalToSave).then((res) => {
      setFileValutation([]);
      setNotMatch([]);
      setConfirmedFile(false);
      handleSavePopUpClose();
      setYearSel(null);
    });
  };

  const handleRemoveFile = () => {
    setYearSel(null);
    setFileValutation([]);
    setNotMatch([]);
    setConfirmedFile(false);
  };

  const handleNoMatchFlag = () => {
    setNoMatchFlag(!noMatchFlag);
  };

  const columns = [
    {
      field: "aic",
      headerName: "AIC",
      headerAlign: "center",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      sortable: false,
      align: "center",
      flex: 0.3,
    },
    {
      field: "nome_commerciale_file",
      headerName: "NOME COMMERCIALE",
      headerAlign: "center",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      sortable: false,
      align: "center",
      flex: 1,
    },
    {
      field: "nome_commerciale",
      headerName: "NOME FARMADATI",
      headerAlign: "center",
      headerClassName: "theme_header",
      cellClassName: (params) => {
        if (params.row.nome_commerciale !== "NO MATCH") {
          return "theme_cell";
        } else {
          return "theme_cell_nomatch";
        }
      },
      align: "center",
      flex: 1,
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          {!confirmedFile && (
            <>
              <div
                style={{
                  display: "inline-block",
                  width: "73%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={handleInstructionOpen}
                  startIcon={<InfoOutlinedIcon />}
                >
                  ISTRUZIONI INSERIMENTO
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "18%",
                }}
              >
                <EXP_FILEF_EMPTY />
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "9%",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  component="label"
                  startIcon={<BackupOutlinedIcon />}
                  disabled={yearSel === null}
                >
                  UPLOAD
                  <input
                    type="file"
                    hidden
                    accept="xlsx, xls"
                    multiple={false}
                    onChange={(e) => handleFile(e)}
                    ref={fileRef}
                  />
                </Button>
              </div>

              <Autocomplete
                disablePortal
                id="region"
                options={filef_years}
                getOptionLabel={(option) => option.nome}
                sx={{
                  mx: "auto",
                  width: "100%",
                  paddingTop: "10%",
                  paddingRight: "40%",
                  paddingLeft: "40%",
                }}
                renderOption={(props, option, index) => {
                  const key = `listItem-${index}-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.nome}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="ANNO" />}
                value={yearSel}
                onChange={(e, newValue) => {
                  setYearSel(newValue);
                }}
              />
              {loadingFileUpload && (
                <div style={{ textAlign: "center", paddingTop: "3%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingFileUpload}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              )}
            </>
          )}
          {confirmedFile && (
            <>
              <div
                style={{
                  display: "inline-block",
                  width: "18%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  onClick={handleRemoveFile}
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                >
                  INDIETRO
                </Button>
              </div>

              <div
                style={{
                  display: "inline-block",
                  width: "25%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={handleInstructionOpen}
                  startIcon={<InfoOutlinedIcon />}
                >
                  ISTRUZIONI INSERIMENTO
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "37%",
                  textAlign: "center",
                }}
              ></div>
              <div
                style={{
                  display: "inline-block",
                  width: "15%",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={handleSavePopUpOpen}
                  startIcon={<SaveOutlinedIcon />}
                >
                  SALVA
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "5%",
                  textAlign: "right",
                }}
              ></div>
              <Box
                sx={{
                  height: "418px",
                  width: "100%",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  paddingTop: "1%",
                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "17px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "18px",
                    border: "1px solid",
                  },
                  "& .theme_cell_nomatch": {
                    fontFamily: "Calibri",
                    fontSize: "18px",
                    color: "#ff1a00",
                    border: "1px solid #000",
                  },
                }}
              >
                {notMatch.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "left",
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="bottom"
                            control={
                              <Switch
                                checked={noMatchFlag}
                                onChange={handleNoMatchFlag}
                              />
                            }
                            label={
                              <h4
                                style={{
                                  fontFamily: "Calibri",
                                  color: noMatchFlag ? "#1976d2" : "#ccc",
                                }}
                              >
                                FILTRO NO MATCH
                              </h4>
                            }
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "right",
                      }}
                    ></div>
                  </>
                )}

                {!noMatchFlag ? (
                  <>
                    <h3
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "right",
                        fontFamily: "Calibri",
                        color: "#1976d2",
                      }}
                    >
                      TOT Elementi: {fileValutation.length}
                    </h3>

                    <DataGridPro
                      columns={columns}
                      rows={fileValutation}
                      getRowId={(row) => row.id_filef_temp}
                      headerHeight={40}
                      rowHeight={33}
                      disableColumnMenu
                      hideFooter
                    />
                  </>
                ) : (
                  <>
                    <h3
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "right",
                        fontFamily: "Calibri",
                        color: "#1976d2",
                      }}
                    >
                      TOT Elementi: {notMatch.length}
                    </h3>
                    <DataGridPro
                      columns={columns}
                      rows={notMatch}
                      getRowId={(row) => row.id_filef_temp}
                      headerHeight={40}
                      rowHeight={33}
                      disableColumnMenu
                      hideFooter
                    />
                  </>
                )}
              </Box>
            </>
          )}
        </div>
        <Dialog
          open={instructionOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="lg"
          keepMounted
          onClose={handleInstructionClose}
          PaperProps={{
            style: {
              backgroundColor: "#fff",
            },
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            sx={{
              fontFamily: "Calibri",
              fontWeight: "bold",
              color: "#1976d2",
              fontSize: 25,
            }}
          >
            ISTRUZIONI D'INSERIMENTO:
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{
                fontFamily: "Calibri",
                fontSize: 25,
              }}
            >
              <div style={{ color: "#1976d2", fontFamily: "Calibri" }}>
                1 - ESPORTARE IL FILE VUOTO DA COMPILARE PER L'INSERIMENTO
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleInstructionClose}>OK</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={savePopUpOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="lg"
          keepMounted
          onClose={handleSavePopUpClose}
          PaperProps={{
            style: {
              backgroundColor: "#fff",
              textAlign: "center",
            },
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{
                fontFamily: "Calibri",
                fontSize: 20,
                paddingBottom: "2%",
              }}
            >
              <div style={{ color: "#1976d2", fontFamily: "Calibri" }}>
                CONFERMI DI VOLER SALVARE IL FILE F INSERITO?
              </div>
            </DialogContentText>
            <Button
              variant="contained"
              component="label"
              color="success"
              startIcon={<CheckOutlinedIcon />}
              onClick={() => handleConfirmSave()}
            >
              CONFERMA
            </Button>
            {loadingFinalSave && (
              <div style={{ textAlign: "center", paddingTop: "1%" }}>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingFinalSave}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                  CARICAMENTO...
                </h6>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default PurchasedFileF;
