import React, { useState, useEffect } from "react";
import "../../../App.css";

//SERVICES
import acquistatoService from "../../../services/acquistato";

//COMPONENTS
import PurchasedForm from "./PurchasedForm";
import PurchasedImport from "./PurchasedImport";

//FUNCTIONS
import {
  handleResetSearch,
  handleSearch,
  createSuggestionsList,
  handleFieldChange,
} from "../../../functions/TAB_SEARCH/ACQ_INSERT_SEARCH";

import { handleColumnOrder } from "../../../functions/TAB_SORT/ACQ_INSERT_SORT";

//COLUMNS
import { ACQ_MAIN_COLUMNS } from "../../../colums/ACQ_COLUMNS";

//EXPS
import EXP_ACQ_MAIN_COMPLETE from "../../../exports/ACQ/EXP_ACQ_MAIN_COMPLETE";

//POPUPS
import ACQ_SAVE_CONFIRM_POPUP from "../../../popup/ACQ_POPUP/ACQ_SAVE_CONFIRM_POPUP";
import PURCHASED_SEARCH_POPUP from "../../../popup/SEARCH_POPUP/PURCHASED_SEARCH_POPUP";

//MUI ELEMENTS
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

//ICONS
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";

//OPTIONS
import acq_search_options from "../../../utils/SEARCH_OPTIONS/acq_search_options.json";

const PurchasedInsert = ({ user, setChangePageFlag }) => {
  const [Dates, setDates] = useState([]);
  const [DatesFlag, setDatesFlag] = useState(false);

  const [errors, setErrors] = useState({});
  const [fileSavedName, setFileSavedName] = useState("");

  const [savePopUpOpen, setSavePopUpOpen] = useState(false);

  const [loadingFinalSave, setLoadingFinalSave] = useState(false);

  const updateDates = (newFileData) => {
    setDates(newFileData);
  };

  const checkedDatesFlag = (flag) => {
    setDatesFlag(flag);
  };

  const handleChangeDates = () => {
    setDatesFlag(false);
    setChangePageFlag(true);
  };

  const [fileData, setFileData] = useState([]);
  const [fileDataSaved, setFileDataSaved] = useState(false);

  const [searchedFlag, setSearchedFlag] = useState(false);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [notFoundFlag, setNotFoundFlag] = useState(false);

  const [selectedField, setSelectedField] = useState(
    acq_search_options.find((option) => option.name === "NOME COMMERCIALE")
  );

  const [searchValue, setSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState(null);

  const [fieldSuggestions, setFieldSuggestions] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {}, []);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const handleSavePopUpClose = () => {
    setSavePopUpOpen(false);
    setLoadingFinalSave(false);
    setFileSavedName("");
  };

  const updateFileData = (newOfferData) => {
    newOfferData.map((Selected) => {
      Selected.date = Dates;
      return newOfferData;
    });

    setFileData(newOfferData);
  };

  const insertedFile = (flag) => {
    setFileDataSaved(flag);
  };

  const handleFinalSavePurchased = async () => {
    const formErrors = ValidateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setLoadingFinalSave(true);
      setErrors({});

      let finalFileData = [];

      const annoACQ = Dates[1].substring(0, 4);

      finalFileData = {
        nome_salvataggio: fileSavedName,
        anno: annoACQ,
        is_valid: true,
        acquistato_inserito: fileData,
      };

      acquistatoService.setToken(user.token);

      await acquistatoService.salvaAcquistato(finalFileData).then((res) => {
        handleRemove();
        handleChangeDates();
        setFileSavedName("");
        setLoadingFinalSave(false);
      });
    }
  };

  const handleRemove = () => {
    setFileData([]);
    insertedFile(false);
    setFileSavedName("");
    setFileDataSaved(false);
  };

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  const ValidateForm = () => {
    const fileSavedNameCheck = fileSavedName;
    const newErrors = {};

    if (!fileSavedNameCheck || fileSavedNameCheck === "") {
      newErrors.fileSavedName = "Inserire il nome per il Database";
    }

    return newErrors;
  };

  const handleSearchClick = () => {
    handleSearch(
      searchValue,
      fileData,
      selectedField,
      setFilteredResults,
      setNotFoundFlag,
      setSearchPopupOpen,
      setSearchedFlag,
      () =>
        handleResetSearch(
          setFilteredResults,
          setSearchedFlag,
          setNotFoundFlag,
          setSearchPopupOpen
        )
    );
  };

  const handleFieldChangeWrapper = (e, newValue) => {
    handleFieldChange(
      newValue,
      fileData,
      setSelectedField,
      setFieldSuggestions,
      createSuggestionsList
    );
  };

  const handleResetClick = () => {
    handleResetSearch(
      setFilteredResults,
      setSearchedFlag,
      setNotFoundFlag,
      setSearchPopupOpen
    );
  };

  const handleColumnOrderWrapper = (columnSEL) => {
    handleColumnOrder(
      columnSEL,
      searchedFlag,
      filteredResults,
      fileData,
      sortOrder,
      setFilteredResults,
      setFileData,
      setSortOrder
    );
  };

  return (
    <>
      {!fileDataSaved && !DatesFlag && (
        <div style={{ paddingTop: "5%" }}>
          <PurchasedForm
            updateDates={updateDates}
            checkedDatesFlag={checkedDatesFlag}
            setChangePageFlag={setChangePageFlag}
          />
        </div>
      )}
      {!fileDataSaved && DatesFlag && (
        <>
          <PurchasedImport
            updateFileData={updateFileData}
            insertedFile={insertedFile}
            dates={Dates}
            handleChangeDates={handleChangeDates}
          />
        </>
      )}
      {fileDataSaved && (
        <>
          <ThemeProvider theme={theme}>
            <div
              style={{
                textAlign: "left",
                display: "inline-block",
                width: "13%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="redtest"
                startIcon={<DeleteOutlinedIcon />}
                onClick={handleRemove}
              >
                RIMUOVI IL FILE
              </Button>
            </div>
            <div
              style={{
                display: "inline-block",
                width: "52%",
                textAlign: "left",
              }}
            >
              <Button
                variant="contained"
                color="bluetest"
                startIcon={<SearchIcon />}
                onClick={() => setSearchPopupOpen(true)}
              >
                CERCA
              </Button>
            </div>
            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "20%",
              }}
            >
              <EXP_ACQ_MAIN_COMPLETE data={fileData} periodo={Dates} />
            </div>
            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "15%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="success"
                startIcon={<SaveOutlinedIcon />}
                onClick={() => setSavePopUpOpen(true)}
              >
                SALVA ACQUISTATO
              </Button>
            </div>

            <Box
              sx={{
                height: "417px",
                width: "100%",
                paddingLeft: "1%",
                paddingRight: "1%",
                paddingTop: "3%",
                "& .theme_header": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                },
                "& .theme_cell": {
                  fontFamily: "Calibri",
                  fontSize: "17px",
                  border: "1px solid",
                },
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  width: "50%",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    color: "#1976d2",
                    fontFamily: "Calibri",
                    fontSize: "22px",
                  }}
                >
                  PERIODO SELEZIONATO:{" "}
                  <b>
                    {visualizzaData(Dates[0].split("T")[0])} -{" "}
                    {visualizzaData(Dates[1].split("T")[0])}{" "}
                  </b>
                </p>
              </div>
              <div
                style={{
                  textAlign: "right",
                  display: "inline-block",
                  width: "50%",
                }}
              >
                <p
                  style={{
                    textAlign: "right",
                    color: "#1976d2",
                    fontFamily: "Calibri",
                    fontSize: "22px",
                  }}
                >
                  <b>TOT ELEMENTI: {fileData.length}</b>
                </p>
              </div>

              {!searchedFlag ? (
                <DataGridPro
                  columns={ACQ_MAIN_COLUMNS}
                  rows={fileData}
                  getRowId={(row) => row.id_acquistato_temp}
                  headerHeight={40}
                  rowHeight={33}
                  onColumnHeaderDoubleClick={handleColumnOrderWrapper}
                  disableColumnMenu
                  hideFooter
                />
              ) : (
                <DataGridPro
                  columns={ACQ_MAIN_COLUMNS}
                  rows={filteredResults}
                  getRowId={(row) => row.id_acquistato_temp}
                  headerHeight={40}
                  rowHeight={33}
                  onColumnHeaderDoubleClick={handleColumnOrderWrapper}
                  disableColumnMenu
                  hideFooter
                />
              )}
            </Box>
            <ACQ_SAVE_CONFIRM_POPUP
              savePopUpOpen={savePopUpOpen}
              handleSavePopUpClose={handleSavePopUpClose}
              handleFinalSavePurchased={handleFinalSavePurchased}
              errors={errors}
              fileSavedName={fileSavedName}
              setFileSavedName={setFileSavedName}
              loadingFinalSave={loadingFinalSave}
            />
            <PURCHASED_SEARCH_POPUP
              searchPopupOpen={searchPopupOpen}
              setSearchPopupOpen={setSearchPopupOpen}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              acq_search_options={acq_search_options}
              handleSearchClick={handleSearchClick}
              notFoundFlag={notFoundFlag}
              selectedField={selectedField}
              fieldSuggestions={fieldSuggestions}
              handleFieldChangeWrapper={handleFieldChangeWrapper}
              searchedFlag={searchedFlag}
              handleResetClick={handleResetClick}
            />
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default PurchasedInsert;
